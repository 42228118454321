
import { IPagingData } from "@/interfaces/IPagingData";
import { PropType } from "@vue/runtime-core";
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    /**
     * Paging data such as the current page in the table, total number of pages, number of rows per page
     */
    pagingData: {
      type: Object as PropType<IPagingData>,
      required: true,
    },
  },
  computed: {
    /**
     * Number of rows on the current page
     */
    rowsCurrentPage() {
      let rowsLeft = this.pagingData.totalCount - this.start + 1;
      return rowsLeft > this.pagingData.pageSize
        ? this.pagingData.pageSize
        : rowsLeft;
    },
    /**
     * The row number of the first row in the current page
     */
    start(): number {
      const start =
        this.pagingData.currentPage * this.pagingData.pageSize -
        this.pagingData.pageSize +
        1;
      return start < 0 ? 0 : start;
    },
    /**
     * The row number of the last row in the current page
     */
    end(): number {
      return this.start + this.rowsCurrentPage - 1;
    },
  },
})
export default class TableInfo extends Vue {}
