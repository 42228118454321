
import { PropType } from "vue";
import { Options, Vue } from "vue-class-component";
import { IPagingData } from "@/interfaces/IPagingData";

@Options({
  props: {
    /**
     * Maximum number of visible page links
     */
    maxVisiblePages: {
      type: Number as PropType<number>,
      required: false,
      default: 5,
    },
    /**
     * Paging data such as the current page in the table, total number of pages, number of rows per page
     */
    pagingData: {
      type: Object as PropType<IPagingData>,
      required: true,
    },
    shouldFocus: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    /**
     * Gets called when the user changes page number
     */
    changePageNumber(pageNumber: number) {
      this.$emit("pageNumberChanged", pageNumber);
    },
    handleKeydown(event: KeyboardEvent) {
      const key = event.key;
      const ctrl = event.ctrlKey;
      const pagingData = this.pagingData;

      if (ctrl) {
        switch (key) {
          case "ArrowLeft":
            // Go to previous page
            if (pagingData.currentPage > 1) {
              this.changePageNumber(pagingData.currentPage - 1);
            }
            break;
          case "ArrowRight":
            // Go to next page
            if (pagingData.currentPage < pagingData.totalPages) {
              this.changePageNumber(pagingData.currentPage + 1);
            }
            break;
          case "ArrowUp":
            // Go to last page
            if (pagingData.currentPage !== pagingData.totalPages) {
              this.changePageNumber(pagingData.totalPages);
            }
            break;
          case "ArrowDown":
            // Go to first page
            if (pagingData.currentPage > 1) {
              this.changePageNumber(1);
            }
            break;
          default:
            break;
        }
      }
    },
  },
  computed: {
    /**
     * Maximum number of visible page links in relation to total number of pages
     */
    maxVisiblePageLinks() {
      return this.pagingData.totalPages < this.maxVisiblePages
        ? this.pagingData.totalPages
        : this.maxVisiblePages;
    },
    /**
     * Start page number in relation to Maximum number of visible page links
     */
    startPageNumber() {
      if (
        this.pagingData.currentPage === 1 ||
        this.pagingData.totalPages === this.maxVisiblePageLinks
      ) {
        return 1;
      }

      let startPage;

      if (this.pagingData.currentPage === this.pagingData.totalPages) {
        startPage = this.pagingData.totalPages - this.maxVisiblePageLinks;
      }

      startPage = this.pagingData.currentPage - 1;

      return startPage < 1 ? 1 : startPage;
    },
    /**
     * Page numbers to display
     */
    pageNumbers(): Array<number> {
      const pageNumbers = new Array<number>();

      const totalPages = Math.min(
        this.startPageNumber + this.maxVisiblePageLinks - 1,
        this.pagingData.totalPages
      );

      for (let i = this.startPageNumber; i <= totalPages; i++) {
        pageNumbers.push(i);
      }

      return pageNumbers;
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.shouldFocus) {
        this.$refs.keydownElement.focus();
      }
    });
  },
})
export default class Pagination extends Vue {
  currentPage!: number;
  totalPages!: number;
  maxVisiblePages!: number;
}
